@import "src/assets/styles/utils.scss";

.PartnersContainer {
  overflow: hidden;
  width: 100%;

  .Marquee {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    position: relative;

    .MarqueeContent {
      display: flex;
      animation: scroll 50s linear infinite;
      will-change: transform;

      img {
        max-width: 150px;
        margin: 0 20px;
        height: auto;
        align-self: center;
        filter: grayscale(100%);
        transition: filter 0.3s ease;

        @include mobile-small {
          max-width: 120px;
        }
      }

      img:hover {
        filter: grayscale(0%);
      }
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}
