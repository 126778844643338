@use 'src/assets/styles/variables';
@import "src/assets/styles/utils.scss";

.NavigationBar {
  display: flex;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  text-transform: uppercase;
  color: variables.$main_black;
  z-index: 999;
  width: 94%;
  font-family: "Clash Grotesk", sans-serif !important;

  @include mobile-medium {
    width: 100%;
  }

  @include mobile-small {
    width: 100%;
  }

  .NavigationBarAlignment {
    display: block;
    width: 100%;
    margin-left: -0.75rem;

    @include mobile-medium {
      margin: 0;
    }

    @include mobile-small {
      margin: 0;
    }

    .NavigationBarM {
      display: flex;

      .NavigationBarSearch {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 1rem 3rem;
        background-color: variables.$main_blue;
        color: variables.$white;
        clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%);
        margin-left: -3rem;

        @include tablet {
          clip-path: none;
          margin: 0;
          padding: 1rem;
        }

        @include mobile-medium {
          clip-path: none;
          padding: 1rem;
          margin: 0;
        }

        @include mobile-small {
          clip-path: none;
          padding: 1rem;
          margin: 0;
        }
      }

      .NavigationBarMain {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 50px;
        background: #FFF;
        justify-content: space-between;
        padding: 0 4rem 0 2.5rem;
        clip-path: polygon(0 0, 100% 0, 98% 100%, 0% 100%);

        @include tablet {
          padding: 0 1rem 0 2.25rem;
          clip-path: none;
        }

        @include mobile-medium {
          clip-path: none;
          padding: 0 0 0 1rem;
        }

        @include mobile-small {
          clip-path: none;
          padding: 0 0 0 1rem;
        }

        .NavigationBarItems {
          display: flex;
          align-items: center;
          gap: 2rem;
          font-size: 12px;

          @include tablet {
            gap: 1rem;
          }

          @include mobile-medium {
            display: none;
          }

          @include mobile-small {
            display: none;
          }

          a {
            color: variables.$main_black;
            text-decoration: none;

            @include mobile-medium {
              align-content: center;
              font-size: 10px;
            }
          }
        }

        .NavigationBarLanguages {
          display: flex;
          font-size: 12px;
          align-items: center;

          a {
            text-decoration: none;
            margin-right: 1rem;
            color: variables.$main_black;
          }

          @include mobile-medium {
            font-size: 10px;
          }

          @include mobile-small {
            font-size: 10px;
          }

          .VolunteersProgram {
            font-weight: bold;
          }

          span {
            margin: 0 1rem;
            cursor: pointer;
            font-family: 'Clash Grotesk', sans-serif !important;
            align-self: flex-end;

            @include tablet {
              margin: 0;
              font-size: 12px;
            }

            @include mobile-medium {
              margin: 0;
              font-size: 12px;
            }

            @include mobile-small {
              margin: 0;
              font-size: 11px;
            }
          }

          .LanguagesMobile {
            border: 1px solid #2A499A;
            width: 60px;
            height: 25px;
            align-content: center;
            border-radius: 5px;
            padding: 0 7px;

            a {
              display: flex;
              justify-content: space-between;
              margin-right: 0;
            }
          }

          .LanguagesFlags {
            display: flex;

            .LanguagesFlagsAlignment {
              border-right: 1px solid #2A499A;

              @include tablet {
                margin-right: 10px;
                width: 30px;
              }
            }
          }
        }
      }
    }

    .NavigationBarMenu {
      display: flex;
      width: 220px;
      height: 50px;
      align-items: center;
      justify-content: start;
      padding: 0 2.5rem;
      background: variables.$main_yellow;
      color: variables.$white;
      clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
      gap: 1rem;
      transition: width 200ms ease-out;

      @include tablet {
        margin-left: -4px;
      }

      @include mobile-medium {
        width: 60px;
        padding: 0 1rem;
        margin-left: -4px;
        clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);

      }

      @include mobile-small {
        width: 60px;
        padding: 0 1rem;
        margin-left: -4px;
        clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);

      }

      &:hover {
        cursor: pointer;
      }

      &.NavigationBarMenuOpen {
        width: 98%;
        clip-path: polygon(0 0, 100% -15%, 97% 100%, 0% 106%);

        @include tablet {
          clip-path: none;
          margin-left: 0;
          width: 100%;
        }

        @include mobile-medium {
          clip-path: none;
          height: auto;
          width: 100%;
          margin-left: 0;
        }

        @include mobile-small {
          clip-path: none;
          height: auto;
          width: 100%;
          margin-left: 0;
        }
      }

      p {
        font-size: 1.5rem;
        text-transform: capitalize;

        @include tablet {
          font-size: 1.2rem;
        }

        @include mobile-medium {
          font-size: 1rem;
        }

        @include mobile-small {
          font-size: 1rem;
        }
      }

      .NavigationLinksMenu {
        display: flex;
        align-items: center;
        gap: 4rem;

        @include desktop-small {
          gap: 1rem;
        }

        @include tablet {
          gap: 1.5rem;
        }

        @include mobile-medium {
          flex-direction: column;
          align-items: flex-start;
          margin: 1rem;
          gap: 1.5rem;
        }

        @include mobile-small {
          flex-direction: column;
          align-items: flex-start;
          margin: 1rem;
          gap: 1.2rem;
        }

        h4 {
          display: flex;
          gap: 0.5rem;

          @include desktop-small {
            font-size: 0.75rem;
          }

          svg {
            height: 12px;
          }
        }
      }
    }

    .NavigationLinksMenuItem {
      display: flex;
      gap: 4rem;
      background-color: white;
      padding: 1rem 2.5rem;
      width: 96%;
      min-height: 12rem;
      clip-path: polygon(0 0, 100% -11%, 90% 100%, 0% 106%);

      @include tablet {
        clip-path: none;
        width: 100%;
      }

      @include mobile-medium {
        display: block;
        clip-path: none;
        gap: 1rem;
        width: 100%;
        padding: 1rem 2rem;
      }

      @include mobile-small {
        display: block;
        clip-path: none;
        gap: 1rem;
        width: 100%;
        padding: 1rem 2rem;
      }

      .NavigationLinksMenuItemSubCat {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1.5rem;
        font-family: "Calibri Light", sans-serif;
        width: 100%;

        @include desktop-small {
          gap: 1rem;
        }

        @include tablet {
          display: block;
          height: 100vh;
        }

        @include mobile-medium {
          display: block;
          height: 100vh;
          overflow-y: scroll;
        }

        @include mobile-small {
          display: block;
          max-height: 100vh;
          overflow-y: scroll;
        }

        h4 {
          color: variables.$main_blue;
          font-weight: bolder;

          @include tablet {
            margin-bottom: 1rem;
          }

          @include mobile-medium {
            margin-bottom: 1rem;
          }

          @include mobile-small {
            margin-bottom: 1rem;
          }
        }

        h5 {
          text-transform: none;
          cursor: pointer;

          @include tablet {
            margin-bottom: 1.5rem;
          }

          @include mobile-medium {
            margin-bottom: 1.5rem;
          }

          @include mobile-small {
            margin-bottom: 1.5rem;
          }

        }
      }

      &.GaratMenu {
        max-height: 12rem;

        @include tablet {
          display: block;
          max-height: 40rem;
        }

        @include mobile-medium {
          display: block;
          max-height: 40rem;
        }

        @include mobile-small {
          max-height: 40rem;
          display: block;
        }
      }
    }
  }

  .LogoImage {
    z-index: 1;

    img {
      height: 128px;
      width: 80px;

      @include mobile-medium {
        height: 80px;
        width: 60px;
      }

      @include mobile-small {
        width: 64px;
        height: 90px;
      }
    }
  }
}