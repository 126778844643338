.cookieBanner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  color: #333;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
  z-index: 9999;
}

.cookieText {
  font-size: 14px;
  margin-bottom: 15px;
}

.cookieText a {
  color: #0056b3;
  text-decoration: none;
  font-weight: bold;
}

.cookieText a:hover {
  text-decoration: underline;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.acceptButton,
.declineButton {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.acceptButton {
  background-color: #1a3a6f;
  color: white;
  margin-left: 5px;
}

.declineButton {
  background-color: #e0e0e0;
  color: #333;
  margin-right: 5px;
}

.acceptButton:hover {
  background-color: #142c54;
}

.declineButton:hover {
  background-color: #c0c0c0;
}
