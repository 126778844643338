@import "src/assets/styles/utils.scss";

.SearchBarInput {
  width: 400px;
  height: 35px;
  border: none;
  padding: 5px;
  font-size: 16px;
  color: #333333;

  @include mobile-medium {
    width: 380px !important;
  }

  @include mobile-small {
    width: 280px !important;
  }
}
