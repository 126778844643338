@use "src/assets/styles/variables";
@import "src/assets/styles/utils.scss";


.SearchContainer {
  padding: 5rem 50px;
  text-align: left;

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.NewsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 30px;
  margin-top: 2rem;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile-medium {
    grid-template-columns: repeat(1, 1fr);
  }

  @include mobile-small {
    grid-template-columns: repeat(1, 1fr);
  }
}

.Pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
