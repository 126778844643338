@use "src/assets/styles/common";
@use "src/assets/styles/variables";
@import "src/assets/styles/utils.scss";

.MainWrapper {
  .ContentWrapper {
    width: 90%;
    margin: auto;

    img {
      width: 100%;
    }

    .MatchesWrapper {
      h2 {
        font-family: "Clash Grotesk", sans-serif;
        font-size: 1.8rem;
        margin-bottom: 2rem;
        color: variables.$main_black;
      }

      .MatchesContainer {
        margin-bottom: 3rem;

        .MatchCard {
          background-color: variables.$white;
          border: 1px solid variables.$bg_color;
          border-radius: 8px;
          padding: 1rem;
          width: 100%;
          max-width: 1700px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include common.mobile-medium {
            display: block;
          }

          @include common.mobile-small {
            display: block;
          }

          .MatchInfo {
            text-align: left;
            margin-right: 2rem;


            @include mobile-medium {
              text-align: center;
              margin-right: 0;
              margin-bottom: 2rem;
            }

            @include mobile-small {
              text-align: center;
              margin-right: 0;
              margin-bottom: 2rem;
            }

            span {
              font-family: "Clash Grotesk", sans-serif;
              font-size: 0.9rem;
              color: grey;
              display: block;

              @include common.mobile-medium {
                font-size: 1rem;
              }

              @include common.mobile-small {
                font-size: 1rem;
              }
            }

            h4 {
              font-family: "Clash Grotesk", sans-serif;
              font-size: 1.2rem;
              color: variables.$main_black;

              @include common.mobile-medium {
                font-size: 1rem;
              }

              @include common.mobile-small {
                font-size: 1rem;
              }
            }
          }

          .MatchTeams {
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15rem;

            @include common.tablet {
              margin-right: 0;
              flex: 0;
            }

            @include common.mobile-medium {
              margin-right: 0 !important;
            }

            @include common.mobile-small {
              margin-right: 0 !important;
            }

            .Team {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 0.5rem;
              width: 100px;

              @include common.tablet {
                width: 80px;
              }

              img {
                width: 50px;
                height: 50px;
                object-fit: contain;
                margin-bottom: 0.5rem;

                @include common.mobile-medium {
                  height: 40px;
                }

                @include common.mobile-small {
                  height: 40px;
                }
              }

              span {
                font-family: "Clash Grotesk", sans-serif;
                font-size: 0.8rem;
                text-align: center;

                @include common.mobile-medium {
                  font-size: 0.8rem;
                }

                @include common.mobile-small {
                  font-size: 0.8rem;
                }
              }
            }

            .Score {
              font-family: "Clash Grotesk", sans-serif;
              font-size: 1.5rem;
              font-weight: bold;
              color: variables.$main_black;
              margin: 0 1rem;

              @include common.mobile-medium {
                font-size: 1.4rem;
                margin: 0;
                width: 70px;
                text-align: center;
              }

              @include common.mobile-small {
                font-size: 1.4rem;
                margin: 0;
                width: 70px;
                text-align: center;
              }
            }

            .PostponedBadge {
              position: absolute;
              right: 10rem;
              top: 50%;
              transform: translateY(-50%);
              font-weight: bold;
              font-size: 1rem;
              color: red;
            }
          }
        }
      }
    }
  }
}

.ClubsColumn {

  span {
    margin-left: 8px;

    @include common.mobile-medium {
      margin-left: 0 !important;
      margin-top: 5px;
      text-align: center;
    }

    @include common.mobile-small {
      margin-left: 0 !important;
      margin-top: 5px;
      text-align: center;
    }
  }

  @include common.mobile-medium {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include common.mobile-small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
