@use 'src/assets/styles/common';
@use 'src/assets/styles/variables';
@import "src/assets/styles/utils";


.MainWrapper {
  width: 80%;
  margin: auto;

  @include tablet {
    width: 100%;
    padding: 60px;
  }

  @include mobile-medium {
    width: 100%;
    padding: 40px;
  }

  @include mobile-small {
    width: 100%;
    padding: 20px;
  }

  .PrimaryTitleWrapper {

    .FirstImage {
      //height: 30rem;
      margin-top: 2rem;
      width: 100%;

      @include tablet {
        width: 100%;
        object-fit: contain;
      }

      @include mobile-medium {
        width: 100%;
        object-fit: contain;
      }

      @include mobile-small {
        width: 100%;
        object-fit: contain;
      }
    }

    .SecondImage {
      width: 60%;
      //height: 30rem;

      @include tablet {
        width: 100%;
        object-fit: contain;
      }

      @include mobile-medium {
        width: 100%;
        object-fit: contain;
      }

      @include mobile-small {
        width: 100%;
        object-fit: contain;
      }
    }

    .Content {
      width: 70%;
      margin: 4rem auto;

      @include tablet {
        width: 100%;
      }

      @include mobile-medium {
        width: 100%;
      }

      @include mobile-small {
        width: 100%;
      }

      h1 {
        color: variables.$main_yellow;
        letter-spacing: 2px;
        padding-bottom: 3rem;
      }

      .Description {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        @include tablet {
          gap: 2rem;
        }

        @include mobile-medium {
          gap: 2rem;
        }

        @include mobile-small {
          gap: 2rem;
        }

        h4 {
          font-size: 1.35rem;

          @include tablet {
            font-size: 1rem;
          }

          @include mobile-medium {
            font-size: 1rem;
          }

          @include mobile-small {
            font-size: 1rem;
          }
        }
      }
    }
  }
}