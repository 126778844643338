@use "src/assets/styles/variables";

.ItemTitle {
  width: 100%;
  background-color: #031F39;
  padding: 1rem 0;
  color: variables.$white;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  border-bottom: 4px solid variables.$main_yellow;
}