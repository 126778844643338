@use "src/assets/styles/common";
@use "src/assets/styles/variables";
@import "src/assets/styles/utils";

.MainWrapper {
  .ContentWrapper {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;

    @include desktop-small {
      width: 80%;
    }

    @include tablet {
      width: 80%;
    }

    @include mobile-small {
      width: 100%;
      padding: 20px;
    }

    p {
      font-family: "Calibri Light", sans-serif;
      font-weight: lighter;
      font-size: 1.5rem;
      margin-bottom: 4rem;

      @include mobile-small {
        font-size: 14px;
      }

      span {
        font-weight: bold;
      }
    }
  }

  .Committee {
    display: flex;
    flex-direction: column;
    align-items: center;

    .SingleItem {
      width: 20rem;
    }

    .Item {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3rem;
      margin-bottom: 4rem;

      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mobile-small {
        grid-template-columns: repeat(1, 1fr);
      }

      .ItemContent {
        position: relative;

        .ItemImage {
          height: 20rem;
          width: 20rem;
          border-bottom: 4px solid variables.$main_yellow;
          object-fit: cover;
        }

        .ItemDescription {
          position: absolute;
          left: 2rem;
          bottom: 2rem;
          color: variables.$white;
          text-align: left;
          font-family: "Calibri Light", sans-serif;
          text-transform: capitalize;

          h2 {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.ImageContainer {
  position: relative;
  display: inline-block;
}

.ImageContainer::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}
