//common (shared) styles
@import "src/assets/styles/utils.scss";

.PrimaryTitleWrapper {
  margin-bottom: 3rem;

  h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 3.25rem;
  }

  h4 {
    font-size: 2rem;
    //had to use other font because lighter font weight was not working
    //we have to import the other versions of 'DINEngschrift'
    font-family: "Calibri Light", sans-serif;
    font-weight: lighter;
    letter-spacing: -1px;
    margin-top: 1rem;
  }
}

.MainWrapper {
  padding-top: 10rem;


  @include mobile-medium {
    padding-top: 8rem !important;
  }

  @include mobile-small {
    padding-top: 8rem !important;
  }

  .ListWrapper {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include desktop-small {
      width: 80%;
    }

    @include tablet {
      width: 100%;
      padding: 0 50px;
    }

    @include mobile-medium {
      width: 100%;
      padding: 0 30px;
    }

    @include mobile-small {
      width: 100%;
      padding: 0 20px;
    }
  }
}