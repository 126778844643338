@import "src/assets/styles/utils.scss";

.VideoCardSizes {
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
  margin-top: 52px;

  @include tablet {
    margin-right: 0;
    width: 100%;
  }

  @include mobile-small {
    margin-right: 0;
  }

  &:hover .PlayButton {
    opacity: 0.7;
  }
}

.ImageContainer {
  position: relative;
}

.NewsImage {
  width: 100%;
  height: auto;
}

.PlayButton {
  width: 92px !important;
  background: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  border: none !important;

  &:hover {
    background: none !important;
    border: none !important;
  }
}
