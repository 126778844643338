@use "src/assets/styles/variables";
@import "src/assets/styles/utils.scss";

.NewsContainer {
  padding: 10rem 75px;
  text-align: left;

  @include tablet {
    padding: 10rem 50px;
  }

  @include mobile-medium {
    padding: 10rem 30px;
  }

  @include mobile-small {
    padding: 10rem 20px;
  }

  h1 {
    text-transform: uppercase;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: variables.$main_blue;
    }
  }

  h2 {
    font-size: 40px;
    font-weight: bolder;
    line-height: 49.2px;
    text-transform: uppercase;

    @include tablet {
      font-size: 30px;
    }

    @include mobile-medium {
      font-size: 24px;
    }

    @include mobile-small {
      font-size: 24px;
    }
  }
}

.ButtonContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.CategoryButton {
  width: 136px;
  height: 38px;
  font-size: 18px;
  cursor: pointer;
  background-color: #eeeeee;
  color: #938f8f;
  border: 1px solid #eeeeee;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: #d0a550;
    color: white;
  }
}

.Active {
  background-color: #d0a550;
  color: white;
  border-color: #d0a550;
}

.News {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 5rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }

  .MainNews {
    position: absolute;
    max-width: 50%;
    bottom: 10%;
    left: 2%;
    margin-left: 10px;
    color: white;
    padding: 14px 32px;
    background: #22499A;
    text-transform: uppercase;

    @include desktop-medium {
      max-width: 70%;
    }

    @include desktop-small {
      max-width: 65%;
    }

    @include tablet {
      max-width: 92%;
    }

    @include mobile-medium {
      max-width: 92% !important;
      padding: 20px;
    }

    @include mobile-small {
      max-width: 90%;
      padding: 15px;
    }

    h1 {
      margin: 0;
      font-size: 42px;
      line-height: 65px;
      letter-spacing: 0.01em;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;


      @include desktop-small {
        font-size: 32px;
        line-height: 48px;
      }

      @include tablet {
        font-size: 28px;
        line-height: 44px;
      }

      @include mobile-medium {
        font-size: 24px;
        line-height: 40px;
      }

      @include mobile-small {
        font-size: 22px;
        line-height: 38px;
      }
    }
  }
}

.AllNews {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 50px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile-medium {
    grid-template-columns: repeat(1, 1fr);
  }

  @include mobile-small {
    grid-template-columns: repeat(1, 1fr);
  }
}

.Pagination {
  display: flex;
  margin-top: 10rem;
  justify-content: center;

  @include tablet {
    margin-top: 4rem;
  }

  @include mobile-medium {
    margin-top: 3rem;
  }

  @include mobile-small {
    margin-top: 2rem;
  }
}
