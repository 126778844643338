// Mobile - Small devices
@mixin mobile-small {
  @media (max-width: 480px) {
    @content;
  }
}

// Mobile - Medium devices (portrait tablets and large phones)
@mixin mobile-medium {
  @media (min-width: 481px) and (max-width: 767px) {
    @content;
  }
}

// Tablet
@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

// Desktop - Small
@mixin desktop-small {
  @media (min-width: 1025px) and (max-width: 1280px) {
    @content;
  }
}

// Desktop - Medium
@mixin desktop-medium {
  @media (min-width: 1281px) and (max-width: 1440px) {
    @content;
  }
}

// Desktop - Large
@mixin desktop-large {
  @media (min-width: 1441px) and (max-width: 1920px) {
    @content;
  }
}

// Optional for ultra-wide support
@mixin ultra-wide {
  @media (min-width: 1921px) {
    @content;
  }
}
