@use 'src/assets/styles/variables';
@import "src/assets/styles/utils.scss";

.Footer {
  background-color: #031F39;
  padding: 3rem;
  border-bottom: 15px solid #DEAD35;
  margin-top: 5rem;
  position: relative;

  @include tablet {
    padding: 1.5rem;
  }

  @include mobile-medium {
    padding: 1.5rem;
  }

  @include mobile-small {
    padding: 1.5rem;
  }

  .FooterLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    @include mobile-medium {
      margin-top: 1.5rem;
    }

    @include mobile-small {
      margin-top: 1.5rem;
    }
  }

  .GeneralInfo {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 5rem;

    @include mobile-medium {
      flex-direction: column;
      align-items: center;
    }

    @include mobile-small {
      flex-direction: column;
      align-items: center;
    }

    .Info {
      width: 230px;
      color: #FFFFFF;

      @include mobile-medium {
        margin-bottom: 2rem;
      }

      @include mobile-small {
        margin-bottom: 2rem;
      }

      h2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.76px;
        margin-bottom: 1rem;

        @include tablet {
          font-size: 16px;
        }

        @include mobile-medium {
          margin-bottom: 0.5rem;
        }

        @include mobile-small {
          margin-bottom: 0.5rem;
        }
      }

      p {
        @include tablet {
          font-size: 14px;
        }
      }
    }
  }

  .FooterTrademark {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 5rem;

    @include tablet {
      font-size: 14px;
    }
  }

  .ScrollToTop {
    width: 40px;
    height: 40px;
    background-color: white;
    color: #2A499A;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
    border-style: none;


  }

  .FooterNav {
    color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;

    @include mobile-medium {
      flex-direction: column;
      align-items: center;
    }

    @include mobile-small {
      flex-direction: column;
      align-items: center;
    }

    .FooterNavLinks {
      width: 400px;
      font-size: 18px;
      font-weight: 500;
      line-height: 22.14px;
      text-align: center;
      text-transform: uppercase;

      @include desktop-small {
        width: 350px;
        font-size: 14px;
      }

      @include tablet {
        width: 320px;
        font-size: 12px;
      }

      @include mobile-medium {
        margin-bottom: 1.5rem;
      }

      @include mobile-small {
        margin-bottom: 1.5rem;
        font-size: 15px;
      }

      a {
        margin-right: 1rem;
        color: #FFFFFF;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: #DEAD35;
        }

      &:last-child {
          margin-right: 0;
        }

        @include tablet {
          margin-right: 10px;
        }
      }
    }

    .FederataTitle {
      width: 400px;
      text-align: center;

      @include desktop-small {
        width: 350px;
      }

      @include tablet {
        width: 320px;
        font-size: 12px;
      }

      @include mobile-medium {
        margin-bottom: 1.5rem;
      }

      @include mobile-small {
        margin-bottom: 1.5rem;
      }
    }

    .SocialMediaIcons {
      width: 400px;
      text-align: center;

      @include desktop-small {
        width: 350px;
        font-size: 16px;
      }

      @include tablet {
        width: 320px;
        font-size: 14px;
      }

      @include mobile-medium {
        margin-bottom: 1.5rem;
      }

      @include mobile-small {
        margin-bottom: 1.5rem;
      }

      a {
        &:first-child img {
          margin-left: 0;
        }
      }

      img {
        width: 24px;
        margin-left: 1rem;

        @include tablet {
          width: 22px;
          margin-left: 10px;
        }
      }
    }

  }
}