@import "src/assets/styles/utils.scss";

.VolunteerFormContainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 500px;

  @include mobile-small {
    overflow: scroll;
  }

  @include mobile-medium {
    overflow: scroll;
  }

  @include tablet {
    overflow: scroll;
  }


  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.36px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 18px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.6px;
    text-align: left;
    margin-bottom: 50px;
  }

  .VolunteerForm {
    .VolunteerFormAlignment {
      display: flex;

      @include mobile-small {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include mobile-medium {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include tablet {
        justify-content: center;
      }

      p {
        margin-bottom: 3px;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: #151515;
      }
    }
  }

  .ButtonAlignment {
    display: flex;
    justify-content: right;
    margin-top: 100px;

    @include tablet {
      margin-top: 20px;
    }

    .ButtonStyle {
      width: 264px;
      height: 41px;
      background: #DEAD35;
      border-radius: 0;
      box-shadow: none;
      clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
      font-size: 16px;
      font-weight: 700;
      line-height: 19.84px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      color: black;
    }
  }
}
