@use "src/assets/styles/common";
@import "src/assets/styles/utils.scss";

.ContactContainer {
  display: flex;
  justify-content: space-evenly;
  padding: 0 2rem;

  @include tablet {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include mobile-medium {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include mobile-small {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 28px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 18px;
  }

  p {
    text-align: left;
    margin-bottom: 50px;
  }

  .ContactFormContainer {
    max-width: 500px;
    padding: 2rem;
    text-align: center;


    @include mobile-medium {
      padding: 3rem 0 0 0;
    }

    @include mobile-small {
      padding: 3rem 0 0 0;
    }
  }

  .ContactForm {
    display: flex;
    flex-direction: column;
  }

  .FormGroup {
    text-align: left;

    p {
      font-weight: 600;
      margin-bottom: 0.3rem;
      color: #444;
    }
  }

  .ButtonAlignment {
    display: flex;
    justify-content: start;
    margin-top: 4rem;
  }

  .SendButton {
    width: 264px;
    height: 41px;
    background: #DEAD35;
    border-radius: 0;
    box-shadow: none;
    clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
    font-size: 16px;
    font-weight: 700;
    line-height: 19.84px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    color: black;
  }

  .MapContainer {
    margin-top: 20px;
    width: 600px;
    //height: 100%;

    @include common.tablet {
      height: 600px;
    }

    @include common.mobile-medium {
      width: 450px;
      height: 350px;
    }

    @include common.mobile-small {
      width: 380px;
      height: 220px;
    }

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.InfoContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 6rem 2rem;

  @include common.mobile-medium {
    flex-direction: column;
  }

  @include common.mobile-small {
    flex-direction: column;
  }

  h2 {
    margin-bottom: 1.5rem;

    @include common.mobile-medium {
      margin-top: 2rem;
    }

    @include common.mobile-small {
      margin-top: 2rem;
    }
  }

  p {
    margin-bottom: 0.5rem;
  }

  span {
    color: #2A499A;
  }
}

