@import "src/assets/styles/utils.scss";

.CardSizes {
  width: 100%;
  border-radius: 0 !important;
  border-style: none;

  @include tablet {
    margin-bottom: 50px;;
  }

  @include mobile-small {
    margin-bottom: 1rem;
  }

  img {
    border-radius: 0 !important;
  }

  .ImageContainer {
    position: relative;

    img {
      width: 100%;
      height: 15rem;
      object-fit: cover;

      @include mobile-small {
        height: 12rem;
      }
    }

    .Badge {
      min-width: 40%;
      position: absolute;
      bottom: 2%;
      background-color: #DEAD35;
      padding: 10px 22px;
      color: white;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    }
  }

  .CardContent {
    text-align: left;
    padding: 22px;

    @include mobile-small {
      padding: 12px;
    }

    h3 {
      font-size: 22px;
      text-transform: none;
      color: #252525;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include mobile-small {
        font-size: 18px;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 16px;
      text-transform: none;
      color: #252525;
      font-weight: lighter;

      @include mobile-small {
        font-size: 14px;
      }
    }
  }
}