@use "src/assets/styles/common";

.TermsContainer {

  margin-bottom: 8rem;


  h1 {
    margin-bottom: 3rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }

  span {
    color: #2A499A;
  }

  .UseOfTheApp {
    ul, li {
      list-style: none;
    }
  }
}