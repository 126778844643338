@import "src/assets/styles/utils";

.CompetitionGameCardContainer {
  position: relative;
  padding: 20px 10px;
  background-color: #f9f9f9;
  border-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include ultra-wide {
    width: 500px;
    //height: 180px;
  }

  @include desktop-large {
    width: 270px;
    height: 150px;
  }

  @include desktop-small {
    font-size: 10px;
    width: 190px;
    height: 140px;
  }

  @include tablet {
    width: 500px;
    height: 180px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 5px;
  }

  @include mobile-medium {
    width: 450px;
  }

  @include mobile-small {
    width: 320px;
    padding: 10px;
  }
}

.TeamSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  width: 70px;

  @include desktop-small {
    width: 65px;
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;

    @include desktop-small {
      width: 30px;
      height: 30px;
    }

    @include tablet {
      width: 55px;
      height: 55px;
    }

    @include mobile-medium {
      width: 60px;
      height: 60px;
    }

    @include mobile-small {
      width: 50px;
      height: 50px;
    }
  }

  .TeamName {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;

    @include desktop-small {
      font-size: 10px;
    }

    @include mobile-medium {
      font-size: 16px;
    }

    @include mobile-small {
      font-size: 14px;
    }
  }
}

.Result {
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 0 16px;

  @include desktop-small {
    margin: 0;
    font-size: 20px;
  }

  @include mobile-medium {
    font-size: 2.2rem;
  }

  @include mobile-small {
    font-size: 2rem;
  }
}

.GameDate {
  color: #8c8c8c;
  font-weight: lighter;
  font-size: 14px;
  text-transform: none;
  margin-bottom: 8px;

}
