.RaportoModal {
  h2 {
    font-weight: normal;
    font-size: 1.2rem;
  }

  .RaportoModalContent {
    display: flex;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
    }
  }
}