@import "./utils";

.ant-carousel .slick-dots-bottom {
  top: 94%;
  right: 81%;


  @include tablet {
    right: 68%;
  }

  @include mobile-medium {
    right: 51%;
  }

  @include mobile-small {
    right: 45%;
  }
}

.ant-carousel .slick-dots li {
  margin-inline: 35px;

  @include mobile-small {
    margin-inline: 23px;
  }
}

.ant-carousel .slick-dots li button {
  width: 70px;
  height: 8px;
  border-radius: 0;

  @include mobile-small {
    width: 48px;
  }
}

.ant-badge.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge.ant-badge-not-a-wrapper .ant-scroll-number {
  height: 24px !important;
  background: #2A499A !important;
  width: 94px;
  border-radius: 0;
  padding-top: 2px;
}

.ant-badge.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: bottom !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-form-item .ant-form-item-control-input-content {
  width: 330px !important;
  height: 40px !important;
  margin-right: 2rem;

  @include desktop-small {
    width: 250px !important;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-select-single .ant-select-selector {
  text-align: left;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-form-item .ant-form-item-explain-error {
  text-align: left;
  font-size: 12px;
  width: 330px !important;

  @include desktop-small {
    width: 250px !important;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  background: #2A499A !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-form-item .ant-form-item-control-input {
  display: block;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-card .ant-card-body{
  padding: 0 !important;
}

:where(.css-ni1kz0).ant-card .ant-card-body {
  padding: 0 !important;
}

:where(.css-ni1kz0).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  width: 330px !important;
  height: 40px !important;

  @include desktop-small {
    width: 250px !important;
  }
}

:where(.css-ni1kz0).ant-input-outlined {
  width: 330px !important;
  height: 40px !important;

  @include desktop-small {
    width: 250px !important;
  }
}

:where(.css-ni1kz0).ant-form-item .ant-form-item-control-input-content {
margin-right: 2rem;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-steps.ant-steps-vertical {
  @include mobile-small {
    overflow: scroll;
  }

  @include mobile-medium {
    overflow: scroll;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-steps {
  margin-bottom: 2rem;

  @include mobile-small {
    justify-content: right;
  }
}

:where(.css-ni1kz0).ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-ni1kz0).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: start;
}

:where(.css-ni1kz0).ant-form-item .ant-form-item-explain-error {
  text-align: start;
  width: 330px;

  @include desktop-small {
    width: 250px !important;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-modal .ant-modal-title {
  font-size: 36px;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-modal .ant-modal-content {
  padding: 25px;
  width: 600px;

  @include mobile-medium {
    width: 450px;
  }

  @include mobile-small {
    width: 380px;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-solid {
  width: 40px;
  height: 40px;
  background-color: white;
  color: red;
  border-radius: 50%;
  margin-top: 1rem;
}

:where(.css-ni1kz0).ant-btn-variant-solid {
  width: 40px;
  height: 40px;
  background-color: white;
  color: red;
  border-radius: 50%;
  margin-top: 1rem;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-row-middle {
  margin-top: 1rem;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
  width: 20px !important;
}

/* First row gets the green color */
.first-row td:first-child {
  border-left: 5px solid #08a045 !important;
}

/* Second and third rows get the light green color */
.second-third-rows td:first-child {
  border-left: 5px solid #87dd6b !important;
}

/* Row before the last two rows gets the orange color */
.penultimate-row td:first-child {
  border-left: 5px solid #ff7a33 !important;
}

/* Last two rows get the red color */
.bottom-two td:first-child {
  border-left: 5px solid #ef4444 !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-space-gap-col-small {
  column-gap: 30px;
  display: flex;
  margin-top: 1rem;

  @include mobile-medium {
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  @include mobile-small {
    flex-wrap: wrap;
    margin-top: 2rem;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-collapse>.ant-collapse-item {
  background: #2A499A !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-collapse>.ant-collapse-item >.ant-collapse-header {
  color: #FFFFFF !important;
  font-size: 15px !important;
  background: #2A499A !important;
}

:where(.css-ni1kz0).ant-collapse>.ant-collapse-item >.ant-collapse-header {
  color: #FFFFFF !important;
  font-size: 15px !important;
  background: #2A499A !important;
}

:where(.css-ni1kz0).ant-space-gap-col-small {
  column-gap: 30px !important;
  display: flex !important;
  margin-top: 1rem !important;
  flex-wrap: wrap;

  //@include mobile-medium {
  //  flex-wrap: wrap;
  //  margin-top: 2rem;
  //}
  //
  //@include mobile-small {
  //  flex-wrap: wrap;
  //  margin-top: 2rem;
  //}
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #8c8c8c;
  height: 30px;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-select .ant-select-arrow .anticon >svg {
  margin-bottom: 5px !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper {
  @include mobile-medium {
    overflow-x: auto;
    background: white;
  }

  @include mobile-small {
    overflow-x: auto;
    background: white;
  }
}

:where(.css-ni1kz0)[class^="ant-table"] [class^="ant-table"], :where(.css-ni1kz0)[class*=" ant-table"] [class^="ant-table"], :where(.css-ni1kz0)[class^="ant-table"] [class*=" ant-table"], :where(.css-ni1kz0)[class*=" ant-table"] [class*=" ant-table"]{
  @include mobile-medium {
    overflow-x: auto;
    background: white;
  }

  @include mobile-small {
    overflow-x: auto;
    background: white;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-collapse {
  background: transparent;
  border: none;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-ni1kz0).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-cell, :where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper tfoot>tr>th, :where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper tfoot>tr>td {
  @include mobile-medium {
    padding: 14px;
  }

  @include mobile-small {
    padding: 12px;
  }
}

:where(.css-ni1kz0).ant-space .ant-space-item>.ant-badge-not-a-wrapper:only-child {

  @include mobile-medium {
    display: inline-block;
  }

  @include mobile-small {
    display: inline-block;
  }
}


:where(.css-ni1kz0).ant-steps {

  @include mobile-medium {
    margin: 0 -53px 12px !important;
  }

  @include mobile-small {
    margin: 0 -53px 12px !important;
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-avatar {
  background: none !important;
}

:where(.css-ni1kz0).ant-avatar {
  background: none !important;
}