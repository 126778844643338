@use "src/assets/styles/common";
@import "src/assets/styles/utils.scss";


.MainWrapper {

}

.FFKShopContainer {
  height: 100vh;
  padding: 10rem 5rem 0;

  h1 {
    font-size: 50px !important;
    color: #2A499A;
  }
}