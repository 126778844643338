@use "src/assets/styles/variables";
@import "src/index";

.MensActivity {
  padding: 0 50px;
  text-transform: uppercase;
  font-size: 52px;
  font-weight: 500;

  @include mobile-medium {
    padding: 0 15px;
  }

  @include mobile-small {
    padding: 20px;
    font-size: 24px;
  }

  h1 {
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: variables.$main_blue;
    }
  }

  .NewsCardContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 52px;
    gap: 2rem;

    @include tablet {
      flex-wrap: wrap;
    }

    @include mobile-medium {
      flex-wrap: wrap;
    }

    @include mobile-small {
      flex-wrap: wrap;
    }
  }
}