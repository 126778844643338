@import "src/assets/styles/utils.scss";

.MainNewsContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 124px;
  height: 100%;

  @include desktop-small {
    padding: 80px 30px 80px;
  }

  @include mobile-medium {
    display: block;
    padding: 0 15px 0;
  }

  @include mobile-small {
    display: block;
    padding: 20px;
  }

  img {
    width: 50%;
    object-fit: cover;

    @include mobile-small {
      width: 100%;
    }

    @include mobile-medium {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .Description {
    display: -webkit-box;
    -webkit-line-clamp: 6 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    max-width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding-left: 20px;
    height: 600px;

    @include mobile-small {
      width: 100%;
      padding-left: 0;
      height: 100%;
    }

    @include mobile-medium {
      width: 100%;
      padding-left: 0;
      height: 100%;

    }
  }

  .Badge {
    margin-bottom: 100px;

    @include mobile-small {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    span {
      margin-right: 1rem;
      color: #2A499A;
    }
  }

  h1 {
    margin-bottom: 24px;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    color: #151515;

    @include mobile-small {
      font-size: 24px;
    }
  }

  p {
    margin-bottom: auto;
    flex-grow: 1;
  }

  button {
    align-self: flex-start;
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    border: none;
    color: #D0A550;
    padding: 10px 16px;
    cursor: pointer;
    background: transparent;
    text-transform: uppercase;
    box-shadow: none;

      &:hover, &:focus {
        background-color: transparent !important;
        color: #D0A550 !important;
      }

    img {
      height: auto;
    }

    &:hover {
      background: transparent;
      color: black;
    }

    @include mobile-medium {
      display: none;
    }

    @include mobile-small {
      display: none;
    }
  }

  .ScrollButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: auto;
    flex-shrink: 0;

    @include mobile-small {
      rotate: 90deg;
      gap: 50px;
    }

    @include mobile-medium {
      rotate: 90deg;
      height: 100px;
      gap: 50px;
    }

    img {
      width: 22px;
      cursor: pointer;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
