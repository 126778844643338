@import "src/assets/styles/utils.scss";

.StepOneContainer {
  display: flex;
  flex-direction: column;
  height: 500px;

  @include mobile-small {
    overflow: scroll;
  }

  @include mobile-medium {
    overflow: scroll;
  }

  img {
    display: flex;
    justify-content: left;
    margin-bottom: 40px;
    width: 60px;

    @include desktop-small {
      width: 40px;
      margin-bottom: 1rem;
    }

    @include desktop-medium {
      width: 50px;
      margin-bottom: 2rem;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.36px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 18px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.6px;
    text-align: left;

    @include desktop-small {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .ButtonAlignment {
    display: flex;
    justify-content: end;
    margin-top: 100px;

    @include desktop-small {
      margin-top: 2rem;
    }

    @include desktop-medium {
      margin-top: 2rem;
    }

    .ButtonStyle {
      width: 264px;
      height: 41px;
      background: #DEAD35;
      border-radius: 0;
      box-shadow: none;
      clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
      font-size: 16px;
      font-weight: 700;
      line-height: 19.84px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      color: black;
    }
  }
}
