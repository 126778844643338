@use "src/assets/styles/common";
@import "src/assets/styles/utils";


.MainWrapper {
  .ListWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include common.tablet {
      width: 100%;
    }

    @include mobile-medium {
      width: 100%;
    }

    @include mobile-small {
      width: 100%;
    }

    .ListItem {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 2rem;
      background-color: #eeeff1;

      @include mobile-medium {
        display: block;
        padding: 1rem;
      }

      @include mobile-small {
        display: block;
        padding: 1rem;
      }

      p {
        font-weight: bolder;
      }

      span {
        @include mobile-medium {
          font-size: 14px;
          color: #2A499A;
        }

        @include mobile-small {
          font-size: 14px;
          color: #2A499A;
        }
      }
    }
  }
}