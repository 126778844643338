@import "src/assets/styles/utils";

.GameCard {
  position: relative;
  //min-width: 320px;
  width: 400px;
  padding: 24px;
  background-color: #f9f9f9;
  border-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  @include ultra-wide {
    width: 500px;
  }

  @include desktop-medium {
    width: 350px;
  }

  @include tablet {
    width: 500px;
  }

  @include desktop-small {
    width: 320px;
  }

  @include mobile-medium {
    width: 450px;
    //padding: 0 50px !important;
  }

  @include mobile-small {
    padding: 10px;
    width: 320px;
  }

  img {
    width: 50px;
  }

  .TeamSection {
    text-align: center;
  }

  .GameDate {
    color: #8C8C8C;
    font-weight: lighter;
    font-size: 14px;
    text-transform: none;
  }

  .GameCompetitionType {
    font-size: 1rem;
    font-family: "Clash Grotesk", sans-serif;
    font-weight: bold;
    text-transform: none;
  }

  .TeamSection {
    text-align: center;
    width: 80px;

    @include mobile-small {
      //width: 70px;
    }

    img {
      width: 100%;
      height: 60px;
      object-fit: contain;

      @include mobile-small {
        height: 50px ;
      }
    }

    .TeamName {
      font-size: 1rem;
      font-family: "Clash Grotesk", sans-serif;
      font-weight: bold;
      padding-top: 0.3rem;

      @include mobile-small {
        font-size: 12px;
      }
    }
  }

  .Result {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    align-self: flex-start;
    margin-left: 10px;
    margin-right: 10px;

    @include mobile-medium {
      font-size: 2.2rem;
    }

    @include mobile-small {
      font-size: 2rem;
    }
  }

  .UpcomingRes {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    align-self: flex-start;
    margin-left: 3px;
    margin-right: 3px;
  }
}
