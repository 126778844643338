@font-face {
    font-family: 'Cabinet Grotesk';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/CabinetGrotesk-Variable.ttf') format('truetype');
}

@font-face {
    font-family: 'Clash Grotesk';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/ClashGrotesk-Variable.ttf') format('truetype');
}

@font-face {
    font-family: 'Clash Grotesk';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/ClashGrotesk-Variable.ttf') format('truetype');
}