@import "src/index";

.VolunteersContainer {
  padding: 10rem 5rem;
  display: flex;

  @include mobile-small {
    padding: 0;
    flex-wrap: wrap;
  }

  @include mobile-medium {
    padding: 0;
    flex-wrap: wrap;
  }

  @include tablet {
    padding: 0;
    flex-wrap: wrap;
  }

  @include desktop-small {
    padding: 10rem 2rem;
    align-items: center;
  }

  @include desktop-medium {
    padding: 10rem 2rem;
  }

  .VolunteersPhoto {
    width: 600px;
    //height: 700px;

    @include mobile-small {
      width: 100%;
      height: 100%;
    }

    @include mobile-medium {
      width: 100%;
      height: 100%;
    }

    @include tablet {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    @include desktop-small {
      width: 400px;
      height: 100%;
    }

    @include desktop-medium {
      height: 715px;
    }
  }

  .VolunteerStepper {
    width: 100% ;
    padding: 0 0 0 65px;

    @include mobile-small {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      width: 100%;
      flex-wrap: wrap;
    }

    @include mobile-medium{
      padding: 30px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      width: 100%;
      flex-wrap: wrap;
    }

    @include tablet{
      padding: 30px 20px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      width: 100%;
      flex-wrap: wrap;
    }

    @include desktop-small {
      padding: 0 0 0 40px;
    }

    @include desktop-medium {
      padding: 0 0 0 50px;
    }

    @include desktop-large {
      padding: 0 0 0 40px;
    }
  }
}
