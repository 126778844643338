@import 'src/assets/styles/typography.scss';
@import "src/assets/styles/antd-custom.scss";
@import "src/assets/styles/utils.scss";
@import "src/assets/styles/variables";

*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  background-color: $bg_color;

  //default elements styles here

  h1 {
    font-family: "Clash Grotesk", sans-serif;
    font-size: 40px;

    @include desktop-large {
      font-size: 30px !important;
    }

    @include desktop-small {
      font-size: 28px !important;
    }

    @include tablet {
      font-size: 24px !important;
    }

    @include mobile-medium {
      font-size: 24px !important;
    }

    @include mobile-small {
      font-size: 24px !important;
    }
  }

  a, p, span {
    font-family: "Cabinet Grotesk", sans-serif !important;
  }

  h2 {
    font-family: "Clash Grotesk", sans-serif;
  }
}


