@use "src/assets/styles/common";
@import "src/assets/styles/utils.scss";

.MainWrapper {
  .ListWrapper {
    width: 90%;
  }

  .CommitteeRow {
    margin-bottom: 20px;
    background: #e7e7e7;
  }

  .MembersWrapper {
    display: flex;
    justify-content: center;
    gap: 3rem;
    border-radius: 5px;
    margin: 1rem 0;

    @include mobile-medium {
      gap: 1rem;
      padding: 0 10px;
    }

    @include mobile-small {
      gap: 1rem;
      padding: 0 10px;
    }
  }

  .Member {
    text-align: center;

    .Name {
      font-size: 24px;
      font-family: "Calibri Light", sans-serif;
      font-weight: bold;

      @include mobile-medium {
        font-size: 16px;
      }

      @include mobile-small {
        font-size: 14px;

      }
    }

    .Status {
      display: block;
      font-family: "Calibri Light", sans-serif;
      font-size: 1rem;

      @include mobile-medium {
        font-size: 14px;
        padding-top: 3px;
      }

      @include mobile-small {
        font-size: 12px;
        padding-top: 5px;
      }
    }
  }
}