@use "src/assets/styles/common";

.DardanetContainer {
  h1 {
    margin-bottom: 3rem;
  }

  p {
    margin: 0.2rem;
    font-size: 15px;
  }

  img {
    margin-bottom: 3rem;
  }
}