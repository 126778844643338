@import "src/assets/styles/utils.scss";

.GalleryContainer {
  margin-top: 125px;
  position: relative;

  img {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58)),
    linear-gradient(266.21deg, rgba(37, 37, 37, 0) 18.37%, #000000 94.87%);
  }

  .GalleryContent {
    position: absolute;
    z-index: 99;
    color: white;
    width: 100%;
    text-align: center;
    top: 80px;

    h1 {
      font-size: 36px;
      margin-bottom: 30px;
    }

    span {
      color: #DEAD35;
    }

    .Carousel {
      width: 90%;
      margin: 0 auto;

      .Slide {
        display: flex;
        justify-content: center;

        .ImageGroup {
          display: flex;
          justify-content: space-between;
          width: 100%;

          img {
            width: 300px;
            height: 400px;
            object-fit: cover;
            border: 4px solid #fff;

            @include tablet {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ArrowContainer {
    position: absolute;
    width: 100%;
    top: 60%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 50px;

    @include tablet {
      padding: 0 15px;
    }

    @include mobile-small {
      padding: 0;
    }
  }

  .PrevArrow, .NextArrow {
    width: 45px;
    height: 50px;
    background-color: #DEAD35;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 24px;
    color: white;
    transition: background-color 0.3s;
  }

  .PrevArrow:hover, .NextArrow:hover {
    color: black;
  }
}
