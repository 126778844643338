@use "src/assets/styles/common";
@use "src/assets/styles/variables";
@import "src/assets/styles/utils.scss";

.MainWrapper {
  .PrimaryTitleWrapper {
    h4 {
      font-size: 1.5rem;

      @include tablet {
        font-size: 1.2rem;
        padding: 0 20px;
      }

      @include mobile-medium {
        font-size: 0.9rem;
        padding: 0 12px;
      }

      @include mobile-small {
        font-size: 0.9rem;
        padding: 0 10px;
      }
    }
  }

  .MainImage {
    width: 100%;
    height: 300px;
    border-top: 1rem solid variables.$main_blue;
    object-fit: cover;

    @include mobile-medium {
      object-fit: contain;
      height: 100%;
    }

    @include mobile-small {
      object-fit: contain;
      height: 100%;
    }
  }

  .ContentWrapper {
    .AllNews {
      width: 92%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 3rem;
      grid-column-gap: 1.5rem;
      margin: -5rem auto auto;

      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mobile-medium {
        grid-template-columns: repeat(1, 1fr);
      }

      @include mobile-small {
        grid-template-columns: repeat(1, 1fr);
      }

      .Card {
        width: 100%;
        height: 10rem;
        background-color: red;
      }
    }
  }
}
