@import "src/assets/styles/utils.scss";

.Container {
  position: relative;
  cursor: pointer;

  img {
    aspect-ratio: 15/6;
    width: 100%;
    object-fit: cover;

    @include tablet {
      aspect-ratio: 16/11;
    }

    @include mobile-medium {
      aspect-ratio: 1/1;
    }

    @include mobile-small {
      aspect-ratio: 1/1;
    }
  }

  .MainNews {
    position: absolute;
    max-width: 50%;
    bottom: 10%;
    left: 2%;
    margin-left: 10px;
    color: white;
    padding: 14px 32px;
    background: #22499A;
    text-transform: uppercase;

    @include desktop-small {
      max-width: 60%;
    }

    @include tablet {
      max-width: 92%;
    }

    @include mobile-medium {
      max-width: 92% !important;
    }

    @include mobile-small {
      max-width: 90%;
      font-size: 34px;
      line-height: 42px;
      padding: 15px;
    }

    h1 {
      margin: 0;
      font-size: 42px;
      line-height: 65px;
      letter-spacing: 0.01em;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include mobile-small {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

}
