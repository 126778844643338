@import "src/index";

.HomeContainer {
  text-align: left;

  .NationalGames {
    text-transform: uppercase;
    font-size: 52px;
    font-weight: 500;
    padding: 125px 50px;
    position: relative;

    @include desktop-small {
      padding: 0 30px;
    }

    @include mobile-medium {
      padding: 50px;
    }

    @include mobile-small {
      padding: 20px;
    }

    h1 {
      cursor: pointer;
      position: relative;
      display: inline-block;

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $main_blue;
      }
    }
  }

  .GameCardContainer {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include mobile-medium {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include mobile-small {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .MensActivity {
    padding: 0 50px;
    text-transform: uppercase;
    font-size: 52px;
    font-weight: 500;

    @include mobile-small {
      padding: 0 24px;
    }

    h1 {
      position: relative;
      display: inline-block;

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $main_blue;
      }
    }

    .NewsCardContainer {
      display: flex;
      justify-content: space-between;
      padding-top: 52px;

      @include tablet {
        flex-wrap: wrap;
      }

      @include mobile-medium {
        flex-wrap: wrap;
      }

      @include mobile-small {
        flex-wrap: wrap;
      }
    }
  }

  .CompetitionGameCard {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include tablet {
      justify-content: center;
      width: 100%;
    }

    @include mobile-medium {
      justify-content: center;
      width: 100%;
    }

    @include mobile-small {
      justify-content: center;
      width: 100%;
    }

  }

  .VideoSection {
    padding: 0 50px;
    text-transform: uppercase;
    font-size: 52px;
    font-weight: 500;
    margin-top: 125px;

    @include desktop-small {
      padding: 0 30px;
    }

    @include mobile-small {
      padding: 0 20px;
      margin-top: 2rem;
    }

    .VisitChannel {
      display: flex;

      @include mobile-small {
        justify-content: center;
        margin-top: 2rem;
      }

      @include mobile-medium {
        justify-content: center;
        margin-top: 4rem;
      }

      @include tablet {
        justify-content: center;
        margin-top: 4rem;
      }

      img {
        width: 10px;
        height: 22px;
        align-self: center;
        margin-left: 2rem;
      }
    }

    h1 {
      position: relative;
      display: inline-block;

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $main_blue;
      }

      @include mobile-small {
        font-size: 24px;
      }
    }

    a {
      font-size: 24px;
      letter-spacing: 0.01em;
      align-self: end;
      text-decoration: none;
      color: #D0A550;

      @include mobile-small {
        font-size: 16px;
      }
    }

    .VideoCardContainer {
      display: flex;
      justify-content: space-between;

      @include tablet {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include mobile-medium {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include mobile-small {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .PartnerSection {
    padding: 0 50px;
    text-transform: uppercase;
    font-size: 52px;
    font-weight: 500;
    margin-top: 125px;

    @include mobile-small {
      padding: 0 20px;
      margin-top: 4rem;
    }

    h1 {
      text-align: center !important;
      margin-bottom: 40px;
      position: relative;

      @include mobile-small {
        font-size: 24px;
        margin-bottom: 1rem;
      }
    }
  }
}

  .LoaderContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
    background: url('../../assets/images/loader-backround.png') center;
    z-index: 9999;
  }


