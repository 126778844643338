@use "src/assets/styles/variables";
@use "src/assets/styles/common";
@import "src/assets/styles/utils";

.MainWrapper {

  .ContentWrapper {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .DescriptionWrapper {
      max-width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: normal;
      text-align: left;

      .DescriptionWrapper b, .DescriptionWrapper strong {
        font-weight: bold !important;
      }

      p {
        margin-bottom: 1rem;
        line-height: 1.6;
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 1rem 0;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }

      th, td {
        border: 1px solid #ddd;
        padding: 8px;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    @include desktop-small {
      width: 80%;
    }

    @include tablet {
      width: 100%;
      padding: 0 50px;
    }

    @include mobile-medium {
      width: 100%;
      padding: 0 30px;
    }

    @include mobile-small {
      width: 100%;
      padding: 0 20px;
    }

    img {
      min-height: 15rem;
      max-height: 25rem;
    }

    .IframeWrapper iframe {
      width: 100%;
      height: 800px;
      border: none;
    }

    .AdditionalImagesWrapper {
        position: relative;

      .AdditionalImages {

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .ArrowWrapper {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .Arrow {
          background-color: variables.$main_yellow;
          color: variables.$white;
          padding: 0 1rem 0.5rem 1rem;
          font-size: 3rem;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}