@use "src/assets/styles/common";

.FFkHistoryContainer {
  h1 {
    margin-bottom: 3rem;
  }

  p {
    margin: 0.2rem;
    font-size: 15px;
  }

  img {
    margin-bottom: 3rem;
  }

  h2 {
    margin-top: 3rem;
  }
}

.IndexColor {
  color: #DEAD35;
  font-weight: bold;
}
