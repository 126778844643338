@import "src/assets/styles/variables";
@import "src/assets/styles/utils.scss";

.CardRowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 7rem;
  border-left: 5px solid $main_yellow;
  background-color: $main_black;
  color: $white;
  padding: 1rem 2rem;

  @include tablet {
    padding: 1rem;
  }

  @include mobile-medium {
    display: block;
    padding: 15px;
  }

  @include mobile-small {
    display: block;
    padding: 15px;
  }

  p {
    font-size: 1.15rem;
    font-family: "Clash Grotesk", sans-serif;
    font-weight: bold;
    text-align: left;

    @include tablet {
      font-size: 1rem;
    }

    @include mobile-medium {
      font-size: 1rem;
      text-align: center;
    }

    @include mobile-small {
      font-size: 0.9rem !important;
      text-align: center;
    }

    span {
      font-family: "Calibri Light", sans-serif;
      font-weight: lighter;
      letter-spacing: -1px;
    }
  }

  .NavLink {
    font-weight: lighter;
    font-size: 1rem;

    @include tablet {
      font-size: 0.9rem;
      color: #2A499A;
    }

    @include mobile-medium {
      font-size: 0.9rem;
      color: #2A499A;
      padding-top: 12px;
    }

    @include mobile-small {
      color: #2A499A;
      padding-top: 10px;
      font-size: 0.7rem;
    }

    &.MarginTop {
      margin-top: 1rem;

      @include mobile-medium {
        margin-top: 10px;
      }

      @include mobile-small {
        margin-top: 10px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.Inverted {
    border-left: 5px solid $main_blue;
    background-color: $main_yellow;
  }

  &.Blue {
    border-left: none;
    background-color: $dark_blue;
  }

  &.AlignCenter {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}